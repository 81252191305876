import React, { useEffect, useState } from 'react';
import banner from "../../assets/images/banner.png";
import "./home.css"
import { getSettings } from '../../firebase/homeApi';

function Home() {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = getSettings((data) => {
      setSettings(data);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Tawk.to entegrasyonu
  useEffect(() => {
    var Tawk_API = window.Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/63fc89994247f20fefe2df18/1gq97j02s';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <>
      <section id="hero" className="d-flex align-items-center justify-content-center">
        <div className="container" data-aos="fade-up">
          <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
            <div className="col-xl-6 col-lg-8">
              <div className="hero-logo"><img src="assets/img/logo.png" className="img-fluid" alt="" /></div>
              <h1 className="hero-title">{settings?.hero?.title}</h1>
              <h2>{settings?.hero?.subtitle}</h2>
            </div>
          </div>

          <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
            {settings?.hero?.features.map((feature, index) => (
              <div key={index} className="col-xl-2 col-md-4">
                <div className="icon-box">
                  <h3>{feature}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <main id="main">

        <section id="about" className="about">
          <div className="container" data-aos="fade-up">

            <div className="row">
              <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                <img src={settings?.about?.image} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 p-5 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                <h3 style={{ marginTop: '50px', marginBottom: '20px' }}>{settings?.about?.title}</h3>
                <p>{settings?.about?.description1}</p>
                <p>{settings?.about?.description2}</p>
              </div>
            </div>

          </div>
        </section>
        <section id="features" className="features">
          <div className="container" data-aos="fade-up">

            <div className="row">
              <div className="image col-lg-6" style={{ backgroundImage: `url("${settings?.features?.image}")` }} data-aos="fade-right"></div>
              <div className="col-lg-6 p-5 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                <p style={{ marginTop: '50px' }}>
                  Altın döküm işlemi aşağıdaki adımları içerir:
                </p>
                <ul>
                  {settings?.features?.steps.map((step, index) => (
                    <li key={index}>
                      <i className="ri-check-double-line"></i>
                      <b>{step.title}.</b> {step.description}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          </div>
        </section>
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>{settings?.services?.title}</h2>
            </div>

            <div className="row">
              {settings?.services?.items.map((service, index) => (
                <div key={index} className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100 * (index + 1)}>
                  <div className="icon-box">
                    <div className="icon"><i className={service.icon}></i></div>
                    <h4>{service.title}</h4>
                    <p>{service.description}</p>
                  </div>
                </div>
              ))}
            </div>

          </div>
        </section>
        <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">

            <div className="text-center">
              <h3>İletişime Geç</h3>
              <p> Hizmetlerimizden faydalanmak için hemen bizimle irtibata geçin.</p>
              <a className="cta-btn" href="/#contact">İletişime Geç</a>
            </div>

          </div>
        </section>
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>{settings?.portfolio?.title}</h2>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  {settings?.portfolio?.categories.map((category, index) => (
                    <li key={index} data-filter={category === 'Tümü' ? '*' : `.filter-${category.toLowerCase()}`} className={index === 0 ? 'filter-active' : ''}>
                      {category}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
              {settings?.portfolio?.items.map((item, index) => (
                <div key={index} className={`col-lg-4 col-md-6 portfolio-item filter-${item.category.toLowerCase()}`}>
                  <div className="portfolio-wrap">
                    <img src={item.image} className="img-fluid" alt="" />
                    <div className="portfolio-info">
                      <h4>{item.title}</h4>
                      <p>{item.category}</p>
                      <div className="portfolio-links">
                        <a href={item.image} data-gallery="portfolioGallery" className="portfolio-lightbox" title={item.title}>
                          <i className="bx bx-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>
        </section>

        <section id="testimonials" className="testimonials">
          <div className="container" data-aos="zoom-in">

            <div className="testimonials-slider swiper">
              <div className="swiper-wrapper">
                {settings?.testimonials?.items.map((testimonial, index) => (
                  <div key={index} className="swiper-slide">
                    <div className="testimonial-item">
                      <img src={testimonial.image} className="testimonial-img" alt="" />
                      <h3>{testimonial.name}</h3>
                      <h4>{testimonial.title}</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        {testimonial.quote}
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="swiper-pagination"></div>
            </div>

          </div>
        </section>

        <section id="counts" className="counts">
          <div className="container" data-aos="fade-up">

            <div className="row no-gutters">
              <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right" data-aos-delay="100"></div>
              <div className="col-xl-7 ps-4 ps-lg-5 pe-4 pe-lg-1 d-flex align-items-stretch" data-aos="fade-left" data-aos-delay="100">
                <div className="content d-flex flex-column justify-content-center">
                  <h3>{settings?.counts?.title}</h3>
                  <p>{settings?.counts?.description}</p>
                  <div className="row">
                    {settings?.counts?.items.map((item, index) => (
                      <div key={index} className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i className="bi bi-emoji-smile"></i>
                          <span data-purecounter-start="0" data-purecounter-end={item.value} data-purecounter-duration="2" className="purecounter"></span>
                          <p><strong>{item.title}</strong> <br />{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>İletişim</h2>
            </div>

            <div>
              <iframe style={{ border: 0, width: '100%', height: '270px' }} src={settings?.contact?.mapUrl} frameBorder="0" allowFullScreen title="map"></iframe>
            </div>

            <div className="row mt-5">

              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Konum:</h4>
                    <p>{settings?.contact?.address}</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>E-Posta:</h4>
                    <p>{settings?.contact?.email}</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Telefon:</h4>
                    <p>{settings?.contact?.phone}</p>
                  </div>

                </div>

              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">

                <form action="https://formbold.com/s/ozeNl" method="post" className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input type="text" name="name" className="form-control" id="name" placeholder="İsminiz" required />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input type="email" className="form-control" name="email" id="email" placeholder="E-Mail Adresiniz" required />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Konu" required />
                  </div>
                  <div className="form-group mt-3">
                    <textarea className="form-control" name="message" rows="5" placeholder="Mesajınız" required></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Yükleniyor</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Mesajınız iletildi. Teşekkürler!</div>
                  </div>
                  <div className="text-center"><button type="submit">Mesajı İlet</button></div>
                </form>

              </div>

            </div>

          </div>
        </section>

      </main>

      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">

              <div className="col-lg-8 col-md-6">
                <div className="footer-info">
                  <img src={banner} alt="site-logo" style={{ height: '16px', marginBottom: '12px' }} />
                  <p>
                    {settings?.contact?.address}<br />
                    {settings?.contact?.address2}<br />
                    <strong>Telefon:</strong> {settings?.contact?.phone}<br />
                    <strong>E-Posta:</strong> {settings?.contact?.email}<br />
                  </p>
                </div>
                <div className="social-links mt-3">
                  <a href={settings?.footer?.socialMedia?.instagram} className="instagram"><i className="bx bxl-instagram"></i></a>
                  <a href={settings?.footer?.socialMedia?.facebook} className="facebook"><i className="bx bxl-facebook"></i></a>
                  <a href={settings?.footer?.socialMedia?.twitter} className="twitter"><i className="bx bxl-twitter"></i></a>
                  <a href={settings?.footer?.socialMedia?.linkedin} className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
              </div>


              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Çalışma Saatleri</h4>
                <ul>
                  {settings?.footer?.workingHours.map((hour, index) => (
                    <li key={index}><i className="bx bx-chevron-right"></i> {hour}</li>
                  ))}
                </ul>
              </div>

            </div>
          </div>
        </div>

      </footer>
      {/* <div id="preloader" /> */}
    </>
  )
}

export default Home;

