import { db } from './config';
import { doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';

// Varsayılan ayarlar
const defaultSettings = {
    hero: {
        title: 'Değerli Maden Döküm Hizmetleri',
        subtitle: 'Sizin başarınız, bizim başarımız.',
        features: [
            'Özel Takı İmalatı',
            'Müşterilerin Seveceği Ürünler',
            'Kaliteye Bağlılık',
            'Son Teknoloji',
            'İhtiyacınız Olan Herşey Tek Bir Yerde'
        ]
    },
    about: {
        title: 'Daha Yakından Bir Bakış.',
        description1: 'İster bir nişan yüzüğü, ister alyans, ister sevdiğiniz bir mücevher parçasının reprodüksiyonu, ister bir müşteriniz için özelleştirilmiş bir parça, ister kuyumcu dükkanınız için yeni bir parça yaratıyor olun, altın genellikle tercih edilen metaldir. Zirve Döküm tarafından sunulan maden döküm takı hizmetleri, yüksek kaliteli takı parçaları yaratmanıza olanak tanır.',
        description2: 'Altın döküm, mücevher yaratma sürecinin bir parçasıdır. Tasarımcıların ve kuyumcuların bir fikri veya tasarımı almasına, bir kalıp oluşturmasına ve sonunda nihai ürünün fiziksel bir reprodüksiyonunu elde etmesine olanak tanır.',
        image: 'assets/img/1.png'
    },
    features: {
        image: 'assets/img/2.png',
        steps: [
            {
                title: 'Balmumu modeli ve döküm hazırlığı',
                description: 'Tasarım kullanılarak, bitmiş parçanın üç boyutlu olarak nasıl görüneceğini temsil etmek için bir balmumu modeli oyulur veya basılır.'
            },
            {
                title: 'Yatırım',
                description: 'Balmumu sap ve bağlı modeller şişe adı verilen özel bir çerçeveye yerleştirilir.'
            },
            // ... diğer adımlar
        ]
    },
    services: {
        title: 'Hizmetlerimiz',
        items: [
            {
                title: 'Bilgisayar Destekli Üretim',
                description: 'Bilgisayar teknolojisinin kullanılarak üretim süreçlerinin tasarlanması, simülasyonu ve kontrol edilmesi',
                icon: 'bx bxl-dribbble'
            },
            {
                title: 'Özel Yapım Takılar',
                description: 'Kişisel zevklere ve ihtiyaçlara göre tasarlanmış ve üretilmiş takı parçaları',
                icon: 'bx bx-file'
            },
            // ... diğer hizmetler
        ]
    },
    portfolio: {
        title: 'Portfolyomuz',
        categories: ['Tümü', 'Kolye', 'Bilezik', 'Yüzük'],
        items: [
            {
                title: 'Yüzük 1',
                category: 'Yüzük',
                image: 'assets/img/portfolio/yuzuk-1.jpg'
            },
            // ... diğer portfolyo öğeleri
        ]
    },
    testimonials: {
        items: [
            {
                name: 'George Herbert',
                title: 'Britanyalı Şair',
                image: 'https://ei7cr2pn3uw.exactdn.com/wp-content/uploads/2021/01/George-Herbert-Portrait.jpg?strip=all&lossy=1&ssl=1',
                quote: 'Altın tüm kilitleri açar, altının gücüne karşı kilit tutmaz.'
            }
        ]
    },
    counts: {
        title: 'Hizmet anlayışımız',
        description: 'Müşterilerimizin maden döküm hizmetleri için Zirve Döküm\'ü seçmelerinin nedenlerinden bazıları:',
        items: [
            {
                title: 'Güven',
                value: 65,
                description: 'Çok aşamalı bir kalite güvence sürecinin kullanılması.'
            },
            // ... diğer sayılar
        ]
    },
    contact: {
        address: 'Mollafenari Mh. Adem Yavuz Sk. No: 18 Kat:3 Yenice İşhanı Fatih/İstanbul',
        email: 'info@zirvedokum.com',
        phone: '0505 874 02 94',
        mapUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12042.589207289104!2d28.9724887!3d41.0110923!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9e6ef95b27f%3A0x76356c32c98c38c2!2zWmlydmUgRMO2a8O8bQ!5e0!3m2!1str!2str!4v1702553151857!5m2!1str!2str'
    },
    footer: {
        workingHours: [
            'Haftaiçi: 09:00–19:00',
            'Cmt: 09:00–15:00',
            'Paz: Kapalı'
        ],
        socialMedia: {
            instagram: 'https://www.instagram.com/zirve_dokum/',
            facebook: '#',
            twitter: '#',
            linkedin: '#'
        }
    }
};

// Ayarları gerçek zamanlı dinle
export const getSettings = (callback) => {
    const settingsRef = doc(db, 'settings', 'home');

    return onSnapshot(settingsRef, (snapshot) => {
        if (snapshot.exists()) {
            callback(snapshot.data());
        } else {
            // Eğer ayarlar yoksa varsayılan ayarları kullan
            callback(defaultSettings);
        }
    });
};

// Ayarları bir kez getir
export const loadSettings = async () => {
    const settingsRef = doc(db, 'settings', 'home');
    const snapshot = await getDoc(settingsRef);

    if (snapshot.exists()) {
        return snapshot.data();
    }
    // Eğer ayarlar yoksa varsayılan ayarları döndür
    return defaultSettings;
};

// Ayarları kaydet
export const saveSettings = async (settings) => {
    const settingsRef = doc(db, 'settings', 'home');
    await setDoc(settingsRef, settings, { merge: true });
};

// İlk kurulum için varsayılan ayarları kaydet
export const initializeDefaultSettings = async () => {
    const settingsRef = doc(db, 'settings', 'home');
    const snapshot = await getDoc(settingsRef);

    if (!snapshot.exists()) {
        await setDoc(settingsRef, defaultSettings);
    }
};
