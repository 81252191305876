import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { loadSettings, saveSettings } from '../../firebase/homeApi';

function HomeSettings() {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('hero');
    const [settings, setSettings] = useState({
        hero: {
            title: '',
            subtitle: '',
            features: []
        },
        about: {
            title: '',
            description1: '',
            description2: '',
            image: ''
        },
        features: {
            image: '',
            steps: []
        },
        services: {
            title: '',
            items: []
        },
        portfolio: {
            title: '',
            categories: [],
            items: []
        },
        testimonials: {
            title: '',
            subtitle: '',
            items: []
        },
        counts: {
            title: '',
            description: '',
            items: []
        },
        contact: {
            address: '',
            address2: '',
            email: '',
            phone: '',
            mapUrl: ''
        },
        footer: {
            workingHours: [],
            socialMedia: {
                instagram: '',
                facebook: '',
                twitter: '',
                linkedin: ''
            }
        }
    });

    useEffect(() => {
        loadSettingsData();
    }, []);

    const loadSettingsData = async () => {
        try {
            const data = await loadSettings();
            if (data) {
                setSettings(data);
            }
        } catch (error) {
            toast.error('Ayarlar yüklenirken bir hata oluştu');
            console.error('Ayarlar yüklenirken hata:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            await saveSettings(settings);
            toast.success('Ayarlar başarıyla kaydedildi');
        } catch (error) {
            toast.error('Ayarlar kaydedilirken bir hata oluştu');
            console.error('Ayarlar kaydedilirken hata:', error);
        }
    };

    const handleChange = (section, field, value, index = null, subfield = null) => {
        setSettings(prev => {
            const newSettings = { ...prev };
            if (index !== null) {
                if (subfield) {
                    newSettings[section][field][index][subfield] = value;
                } else {
                    newSettings[section][field][index] = value;
                }
            } else {
                if (field) {
                    newSettings[section][field] = value;
                } else {
                    newSettings[section] = value;
                }
            }
            return newSettings;
        });
    };

    const addArrayItem = (section, field, defaultItem) => {
        setSettings(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: [...prev[section][field], defaultItem]
            }
        }));
    };

    const removeArrayItem = (section, field, index) => {
        setSettings(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: prev[section][field].filter((_, i) => i !== index)
            }
        }));
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'hero':
                return (
                    <div>
                        <h4 className="mb-4">Ana Bölüm Ayarları</h4>
                        <div className="mb-3">
                            <label className="form-label">Ana Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.hero?.title || ''}
                                onChange={(e) => handleChange('hero', 'title', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Alt Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.hero?.subtitle || ''}
                                onChange={(e) => handleChange('hero', 'subtitle', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Özellikler</label>
                            {settings?.hero?.features?.map((feature, index) => (
                                <div key={index} className="input-group mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={feature || ''}
                                        onChange={(e) => handleChange('hero', 'features', e.target.value, index)}
                                    />
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => removeArrayItem('hero', 'features', index)}
                                    >
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </div>
                            ))}
                            <button
                                className="btn btn-secondary"
                                onClick={() => addArrayItem('hero', 'features', '')}
                            >
                                Özellik Ekle
                            </button>
                        </div>
                    </div>
                );

            case 'about':
                return (
                    <div>
                        <h4 className="mb-4">Hakkımızda Bölümü</h4>
                        <div className="mb-3">
                            <label className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.about?.title || ''}
                                onChange={(e) => handleChange('about', 'title', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Açıklama 1</label>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={settings?.about?.description1 || ''}
                                onChange={(e) => handleChange('about', 'description1', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Açıklama 2</label>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={settings?.about?.description2 || ''}
                                onChange={(e) => handleChange('about', 'description2', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Resim URL</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.about?.image || ''}
                                onChange={(e) => handleChange('about', 'image', e.target.value)}
                            />
                        </div>
                    </div>
                );
            // ... önceki kodlar aynı ...

            case 'features':
                return (
                    <div>
                        <h4 className="mb-4">Özellikler Bölümü</h4>
                        <div className="mb-3">
                            <label className="form-label">Resim URL</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.features?.image || ''}
                                onChange={(e) => handleChange('features', 'image', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Adımlar</label>
                            {settings?.features?.steps?.map((step, index) => (
                                <div key={index} className="card mb-3">
                                    <div className="card-body">
                                        <div className="mb-2">
                                            <label className="form-label">Başlık</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={step?.title || ''}
                                                onChange={(e) => handleChange('features', 'steps', e.target.value, index, 'title')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Açıklama</label>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                value={step?.description || ''}
                                                onChange={(e) => handleChange('features', 'steps', e.target.value, index, 'description')}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => removeArrayItem('features', 'steps', index)}
                                        >
                                            Adımı Sil
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button
                                className="btn btn-secondary"
                                onClick={() => addArrayItem('features', 'steps', { title: '', description: '' })}
                            >
                                Adım Ekle
                            </button>
                        </div>
                    </div>
                );

            case 'services':
                return (
                    <div>
                        <h4 className="mb-4">Hizmetler Bölümü</h4>
                        <div className="mb-3">
                            <label className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.services?.title || ''}
                                onChange={(e) => handleChange('services', 'title', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Hizmetler</label>
                            {settings?.services?.items?.map((service, index) => (
                                <div key={index} className="card mb-3">
                                    <div className="card-body">
                                        <div className="mb-2">
                                            <label className="form-label">Başlık</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={service?.title || ''}
                                                onChange={(e) => handleChange('services', 'items', e.target.value, index, 'title')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Açıklama</label>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                value={service?.description || ''}
                                                onChange={(e) => handleChange('services', 'items', e.target.value, index, 'description')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">İkon Sınıfı</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={service?.icon || ''}
                                                onChange={(e) => handleChange('services', 'items', e.target.value, index, 'icon')}
                                            />
                                            <small className="text-muted">Örnek: bx bxl-dribbble, bx bx-file, bx bx-tachometer</small>
                                        </div>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => removeArrayItem('services', 'items', index)}
                                        >
                                            Hizmeti Sil
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button
                                className="btn btn-secondary"
                                onClick={() => addArrayItem('services', 'items', { title: '', description: '', icon: '' })}
                            >
                                Hizmet Ekle
                            </button>
                        </div>
                    </div>
                );

            case 'portfolio':
                return (
                    <div>
                        <h4 className="mb-4">Portfolyo Bölümü</h4>
                        <div className="mb-3">
                            <label className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.portfolio?.title || ''}
                                onChange={(e) => handleChange('portfolio', 'title', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Kategoriler</label>
                            {settings?.portfolio?.categories?.map((category, index) => (
                                <div key={index} className="input-group mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={category || ''}
                                        onChange={(e) => handleChange('portfolio', 'categories', e.target.value, index)}
                                    />
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => removeArrayItem('portfolio', 'categories', index)}
                                    >
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </div>
                            ))}
                            <button
                                className="btn btn-secondary"
                                onClick={() => addArrayItem('portfolio', 'categories', '')}
                            >
                                Kategori Ekle
                            </button>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Portfolyo Öğeleri</label>
                            {settings?.portfolio?.items?.map((item, index) => (
                                <div key={index} className="card mb-3">
                                    <div className="card-body">
                                        <div className="mb-2">
                                            <label className="form-label">Başlık</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item?.title || ''}
                                                onChange={(e) => handleChange('portfolio', 'items', e.target.value, index, 'title')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Kategori</label>
                                            <select
                                                className="form-control"
                                                value={item?.category || ''}
                                                onChange={(e) => handleChange('portfolio', 'items', e.target.value, index, 'category')}
                                            >
                                                {settings?.portfolio?.categories?.map((cat, i) => (
                                                    <option key={i} value={cat}>{cat}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Resim URL</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item?.image || ''}
                                                onChange={(e) => handleChange('portfolio', 'items', e.target.value, index, 'image')}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => removeArrayItem('portfolio', 'items', index)}
                                        >
                                            Öğeyi Sil
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button
                                className="btn btn-secondary"
                                onClick={() => addArrayItem('portfolio', 'items', {
                                    title: '',
                                    category: settings?.portfolio?.categories?.[0] || '',
                                    image: ''
                                })}
                            >
                                Portfolyo Öğesi Ekle
                            </button>
                        </div>
                    </div>
                );

            case 'testimonials':
                return (
                    <div>
                        <h4 className="mb-4">Referanslar Bölümü</h4>
                        <div className="mb-3">
                            <label className="form-label">Referanslar</label>
                            {settings?.testimonials?.items?.map((item, index) => (
                                <div key={index} className="card mb-3">
                                    <div className="card-body">
                                        <div className="mb-2">
                                            <label className="form-label">İsim</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item?.name || ''}
                                                onChange={(e) => handleChange('testimonials', 'items', e.target.value, index, 'name')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Unvan</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item?.title || ''}
                                                onChange={(e) => handleChange('testimonials', 'items', e.target.value, index, 'title')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Alıntı</label>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                value={item?.quote || ''}
                                                onChange={(e) => handleChange('testimonials', 'items', e.target.value, index, 'quote')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Resim URL</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item?.image || ''}
                                                onChange={(e) => handleChange('testimonials', 'items', e.target.value, index, 'image')}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => removeArrayItem('testimonials', 'items', index)}
                                        >
                                            Referansı Sil
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button
                                className="btn btn-secondary"
                                onClick={() => addArrayItem('testimonials', 'items', {
                                    name: '',
                                    title: '',
                                    quote: '',
                                    image: ''
                                })}
                            >
                                Referans Ekle
                            </button>
                        </div>
                    </div>
                );

            case 'counts':
                return (
                    <div>
                        <h4 className="mb-4">İstatistikler Bölümü</h4>
                        <div className="mb-3">
                            <label className="form-label">Başlık</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.counts?.title || ''}
                                onChange={(e) => handleChange('counts', 'title', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Açıklama</label>
                            <textarea
                                className="form-control"
                                rows="3"
                                value={settings?.counts?.description || ''}
                                onChange={(e) => handleChange('counts', 'description', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">İstatistikler</label>
                            {settings?.counts?.items?.map((item, index) => (
                                <div key={index} className="card mb-3">
                                    <div className="card-body">
                                        <div className="mb-2">
                                            <label className="form-label">Başlık</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item?.title || ''}
                                                onChange={(e) => handleChange('counts', 'items', e.target.value, index, 'title')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Değer</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={item?.value || 0}
                                                onChange={(e) => handleChange('counts', 'items', e.target.value, index, 'value')}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Açıklama</label>
                                            <textarea
                                                className="form-control"
                                                value={item?.description || ''}
                                                onChange={(e) => handleChange('counts', 'items', e.target.value, index, 'description')}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => removeArrayItem('counts', 'items', index)}
                                        >
                                            İstatistiği Sil
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button
                                className="btn btn-secondary"
                                onClick={() => addArrayItem('counts', 'items', { title: '', value: 0, description: '' })}
                            >
                                İstatistik Ekle
                            </button>
                        </div>
                    </div>
                );

            case 'contact':
                return (
                    <div>
                        <h4 className="mb-4">İletişim Bölümü</h4>
                        <div className="mb-3">
                            <label className="form-label">Adres</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.contact?.address || ''}
                                onChange={(e) => handleChange('contact', 'address', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Adres 2</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.contact?.address2 || ''}
                                onChange={(e) => handleChange('contact', 'address2', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">E-posta</label>
                            <input
                                type="email"
                                className="form-control"
                                value={settings?.contact?.email || ''}
                                onChange={(e) => handleChange('contact', 'email', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Telefon</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.contact?.phone || ''}
                                onChange={(e) => handleChange('contact', 'phone', e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Google Harita URL</label>
                            <input
                                type="text"
                                className="form-control"
                                value={settings?.contact?.mapUrl || ''}
                                onChange={(e) => handleChange('contact', 'mapUrl', e.target.value)}
                            />
                            <small className="text-muted">
                                Google Maps'ten embed kodunu kopyalayıp yapıştırın.
                                Örnek: https://www.google.com/maps/embed?pb=...
                            </small>
                        </div>
                    </div>
                );

            case 'footer':
                return (
                    <div>
                        <h4 className="mb-4">Alt Bilgi Bölümü</h4>
                        <div className="mb-3">
                            <label className="form-label">Çalışma Saatleri</label>
                            {settings?.footer?.workingHours?.map((hour, index) => (
                                <div key={index} className="input-group mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={hour || ''}
                                        onChange={(e) => handleChange('footer', 'workingHours', e.target.value, index)}
                                    />
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => removeArrayItem('footer', 'workingHours', index)}
                                    >
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </div>
                            ))}
                            <button
                                className="btn btn-secondary"
                                onClick={() => addArrayItem('footer', 'workingHours', '')}
                            >
                                Çalışma Saati Ekle
                            </button>
                        </div>
                        <div className="mb-3">
                            <h5>Sosyal Medya Bağlantıları</h5>
                            <div className="mb-2">
                                <label className="form-label">Instagram</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={settings?.footer?.socialMedia?.instagram || ''}
                                    onChange={(e) => handleChange('footer', 'socialMedia', {
                                        ...settings?.footer?.socialMedia,
                                        instagram: e.target.value
                                    })}
                                />
                            </div>
                            <div className="mb-2">
                                <label className="form-label">Facebook</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={settings?.footer?.socialMedia?.facebook || ''}
                                    onChange={(e) => handleChange('footer', 'socialMedia', {
                                        ...settings?.footer?.socialMedia,
                                        facebook: e.target.value
                                    })}
                                />
                            </div>
                            <div className="mb-2">
                                <label className="form-label">Twitter</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={settings?.footer?.socialMedia?.twitter || ''}
                                    onChange={(e) => handleChange('footer', 'socialMedia', {
                                        ...settings?.footer?.socialMedia,
                                        twitter: e.target.value
                                    })}
                                />
                            </div>
                            <div className="mb-2">
                                <label className="form-label">LinkedIn</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={settings?.footer?.socialMedia?.linkedin || ''}
                                    onChange={(e) => handleChange('footer', 'socialMedia', {
                                        ...settings?.footer?.socialMedia,
                                        linkedin: e.target.value
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    if (loading) {
        return <div className="text-center p-5">Yükleniyor...</div>;
    }

    return (
        <div className="container py-5 mt-5">

            <div className="row">
                <div className="col-md-3">
                    <div className="list-group">
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'hero' ? 'active' : ''}`}
                            onClick={() => setActiveTab('hero')}
                        >
                            Ana Bölüm
                        </button>
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'about' ? 'active' : ''}`}
                            onClick={() => setActiveTab('about')}
                        >
                            Hakkımızda
                        </button>
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'features' ? 'active' : ''}`}
                            onClick={() => setActiveTab('features')}
                        >
                            Özellikler
                        </button>
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'services' ? 'active' : ''}`}
                            onClick={() => setActiveTab('services')}
                        >
                            Hizmetler
                        </button>
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'portfolio' ? 'active' : ''}`}
                            onClick={() => setActiveTab('portfolio')}
                        >
                            Portfolyo
                        </button>
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'testimonials' ? 'active' : ''}`}
                            onClick={() => setActiveTab('testimonials')}
                        >
                            Referanslar
                        </button>
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'counts' ? 'active' : ''}`}
                            onClick={() => setActiveTab('counts')}
                        >
                            İstatistikler
                        </button>
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'contact' ? 'active' : ''}`}
                            onClick={() => setActiveTab('contact')}
                        >
                            İletişim
                        </button>
                        <button
                            className={`list-group-item list-group-item-action ${activeTab === 'footer' ? 'active' : ''}`}
                            onClick={() => setActiveTab('footer')}
                        >
                            Alt Bilgi
                        </button>
                    </div>
                </div>

                <div className="col-md-9">
                    <div className="card">
                        <div className="card-body">
                            {renderTabContent()}
                        </div>
                    </div>
                    <div className="card-footer text-end mt-4 mr-2">
                        <button className="btn btn-primary" onClick={handleSave}>
                            Değişiklikleri Kaydet
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeSettings;